import React from 'react'
import * as s from '../styles/lisbon.module.scss'

const LisbonPricing = () => {
  return (
    <div className={s.container}>
      <h1 className={s.header}>Lisbon Pricing</h1>
      <h2 className={s.subhead}>Apparel</h2>
      <ul className={s.grid}>
        <li className={s.item}>
          Gradient Sock <span>$25.00</span>
        </li>
        <li className={s.item}>
          SOL MATE Sock <span>$25.00</span>
        </li>
        <li className={s.item}>
          DEGEN Hat <span>$30.00</span>
        </li>
        <li className={s.item}>
          NFA T-Shirt <span>$32.00</span>
        </li>
        <li className={s.item}>
          Solana Spaces Crewneck <span>$80.00</span>
        </li>
        <li className={s.item}>
          Spaces DAO T-Shirt <span>$40.00</span>
        </li>
      </ul>
      <h2 className={s.subhead}>Partner Apparel</h2>
      <ul className={s.grid}>
        <li className={s.item}>
          Ape-ish Tee <span>$60.27</span>
        </li>
        <li className={s.item}>
          FTX Hat <span>$40.00</span>
        </li>
        <li className={s.item}>
          FTX Hoodie <span>$70.00</span>
        </li>
        <li className={s.item}>
          MonkeDAO SS Tee <span>$36.90</span>
        </li>
        <li className={s.item}>
          MonkeDAO LS Tee <span>$49.20</span>
        </li>
        <li className={s.item}>
          MonkeDAO Hoodie <span>$79.95</span>
        </li>
        <li className={s.item}>
          MonkeDAO Dad Hat <span>$24.60</span>
        </li>
        <li className={s.item}>
          Solana previous HH patches <span>0.5 SOL</span>
        </li>
        <li className={s.item}>
          SOL Solana breakpoint patch <span>1 SOL</span>
        </li>
      </ul>
      <h2 className={s.subhead}>Stickers</h2>
      <ul className={s.grid}>
        <li className={s.item}>
          Spaces DAO Sticker <span>$4.00</span>
        </li>
        <li className={s.item}>
          Lisbon themed Sticker <span>$4.00</span>
        </li>
        <li className={s.item}>
          Cable Car Sticker <span>$4.00</span>
        </li>
        <li className={s.item}>
          @solanaspaces sticker <span>$4.00</span>
        </li>
        <li className={s.item}>
          BomDiaDAO sticker <span>$4.00</span>
        </li>
      </ul>
      <h2 className={s.subhead}>Misc</h2>
      <ul className={s.grid}>
        <li className={s.item}>
          Pin - 3 Pack <span>$15.00</span>
        </li>
        <li className={s.item}>
          Lego Set <span>$420.69</span>
        </li>
      </ul>
    </div>
  )
}

export default LisbonPricing
